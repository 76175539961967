import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import {
  SellingPlanCategory,
  SellingPlanGroupInput,
  SellingPlanInput,
  SellingPlanPricingPolicyAdjustmentType,
} from "@smartrr/shared/shopifyGraphQL/api";
import { ValidSortMethods } from "@smartrr/shared/utils/sortBy";
import { MappedPurchasableVariant } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import styled from "styled-components";

import { ProductOfferingSettings } from "./models";

interface IListItemBase {
  variantToPurchasableMap: MappedPurchasableVariant;
  isProductOrVariantLinkedLoading: boolean;
  handleRemoveProductsAndVariants: (variantIds: string[], productId?: string | undefined) => void;
}

export interface ProductListItemProps extends IListItemBase {
  purchasable: IPurchasable;
  variantIds: string[];
}

export interface VariantListItemProps extends IListItemBase {
  index: number;
  variant: ILinkedVariantWithPurchaseableName;
  linkedProducts: IPurchasable[];
}

export interface ILinkedVariantWithPurchaseableName extends IPurchasableVariant {
  purchasableName: string;
}

export const emptySellingPlanGroup: SellingPlanGroupInput = {
  merchantCode: "",
  name: "",
  options: [],
};

export const DEFAULT_PAGE_SIZE = 50;

export const defaultOffering: ProductOfferingSettings = {
  isActive: false,
  exists: false,
  newProductVariantId: "",
  originalProductVariantId: "",
  orderNumber: 1,
  sequentialEndBehavior: "",
  sequentialProducts: [],
};

export const emptySellingPlan: SellingPlanInput = {
  name: "",
  description: "",
  options: [""],
  category: SellingPlanCategory.Subscription,
  pricingPolicies: [
    {
      fixed: {
        adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
        adjustmentValue: {
          percentage: 0,
        },
      },
    },
  ],
};

export const productSortingOptions: { label: string; value: string }[] = [
  { label: "Manual", value: ValidSortMethods.MANUAL },
  { label: "Product title A-Z", value: ValidSortMethods.PRODUCT_TITLE_AZ },
  { label: "Newest", value: ValidSortMethods.NEWEST },
  { label: "Oldest", value: ValidSortMethods.OLDEST },
];

export const variantSortingOptions: { label: string; value: string }[] = [
  { label: "Manual", value: ValidSortMethods.MANUAL },
  { label: "Product title A-Z", value: ValidSortMethods.PRODUCT_TITLE_AZ },
  { label: "Highest price", value: ValidSortMethods.PRODUCT_PRICE_HIGHEST },
  { label: "Lowest price", value: ValidSortMethods.PRODUCT_PRICE_LOWEST },
  { label: "Newest", value: ValidSortMethods.NEWEST },
  { label: "Oldest", value: ValidSortMethods.OLDEST },
  { label: "Highest inventory", value: ValidSortMethods.PRODUCT_INVENTORY_HIGHEST },
  { label: "Lowest inventory", value: ValidSortMethods.PRODUCT_INVENTORY_LOWEST },
];

export const reorderArray = (oldIndex: number, direction: string, originalArray: IPurchasableVariant[]) => {
  const newIndex = oldIndex + (direction === "UP" ? -1 : 1);
  const movedItem = originalArray.find((item, index) => index === oldIndex);
  const remainingItems = originalArray.filter((item, index) => index !== oldIndex);

  return [...remainingItems.slice(0, newIndex), movedItem, ...remainingItems.slice(newIndex)];
};

export enum EndBehaviorMethods {
  RESTART = "RESTART",
  CONTINUE = "CONTINUE",
  TERMINATE = "TERMINATE",
}

export enum EndBehaviorOptions {
  RESTART = "Recycle items from beginning of sequence",
  CONTINUE = "Send final item in perpetuity",
  TERMINATE = "End subscription",
}

export const VariantOrProductTitle = styled.span.attrs((props: { variation: "strong" }) => ({ ...props }))`
  display: block;
  font-weight: ${props => (props.variation === "strong" ? "bold" : "normal")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  @media only screen and (max-width: 880px) {
    width: 265px;
  }
  @media only screen and (max-width: 820px) {
    width: 28vw;
  }
  @media only screen and (max-width: 780px) {
    width: 24vw;
  }
  @media only screen and (max-width: 768px) {
    width: 42vw;
  }
  @media only screen and (max-width: 550px) {
    width: 26vw;
  }
`;

export const TitleForLinkedToProductVariants = styled.span`
  display: block;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 270px;
  @media only screen and (max-width: 880px) {
    width: 225px;
  }
  @media only screen and (max-width: 830px) {
    width: 24vw;
  }
  @media only screen and (max-width: 780px) {
    width: 20vw;
  }
  @media only screen and (max-width: 750px) {
    width: 38vw;
  }
  @media only screen and (max-width: 555px) {
    width: 22vw;
  }
`;

export const ChevronContainer = styled.div`
  display: flex;

  & > *:first-child {
    margin-right: 0.5rem;
  }
`;

export const ResourceItemContainer = styled.div`
  & .Polaris-ResourceItem__Container {
    padding-left: 40px;
    padding-right: 0;
  }
`;

export const ProductAndVariantTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
